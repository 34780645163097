import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { PropTypes } from 'prop-types';

import SuccessAlert from 'components/SuccessAlert';

import Layout from 'common/Layout';

import { STORAGE_KEYS } from 'utils/local-store-utils';

import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Box, Button, ButtonBase, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledButtonBase = styled(ButtonBase)(({ theme: { palette } }) => ({
  height: '200px',
  width: '200px',
  borderRadius: '50%',
  overflow: 'hidden',
  margin: 'auto',
  display: 'block',

  '&:hover': {
    zIndex: 1,
    backgroundColor: palette.hover.main,

    '&:after': {
      content: '""', // ::before and ::after both require content
      position: 'absolute',
      top: '0',
      left: '0',
      width: '100%',
      height: '100%',
      backgroundColor: palette.hover.main,
      opacity: '.7'
    }
  }
}));

function AccountSettings(props) {
  const { onUpdateUserSettings, updateUserMetadata, updating } = props;

  const navigate = useNavigate();

  const [displaySuccess, setDisplaySuccess] = useState(false);
  const [mode, setMode] = useState(JSON.parse(localStorage.getItem(STORAGE_KEYS.modePreferred)));

  const handleSave = async () => {
    const variables = { updates: {} };

    if (mode !== JSON.parse(localStorage.getItem(STORAGE_KEYS.modePreferred))) {
      variables.updates.mode_preferred = mode;
      localStorage.setItem(STORAGE_KEYS.modePreferred, JSON.stringify(mode));
      updateUserMetadata({
        [STORAGE_KEYS.modePreferred]: mode
      });
    }

    onUpdateUserSettings({
      variables
    }).then(() => {
      if (variables.updates.mode_preferred) navigate(0);
    });
  };

  const handlePreferredModeChange = e => {
    setMode(e.target.value);
  };

  return (
    <Layout maxWidth="sm">
      <Box display="flex" my={3} pb={1} justifyContent="space-between" alignItems="center">
        <Typography variant="body1">Preferred Mode</Typography>
        <ToggleButtonGroup
          disabled={updating}
          color="primary"
          value={mode}
          exclusive
          onChange={handlePreferredModeChange}
        >
          <ToggleButton value={'light'}>
            <FontAwesomeIcon icon={solid('sun')} />
            Light
          </ToggleButton>
          <ToggleButton value={'dark'}>
            <FontAwesomeIcon icon={solid('moon')} />
            Dark
          </ToggleButton>
        </ToggleButtonGroup>
      </Box>

      <Button
        variant="contained"
        size="large"
        color="primary"
        disabled={updating || !(mode !== JSON.parse(localStorage.getItem(STORAGE_KEYS.modePreferred)))}
        onClick={handleSave}
      >
        {updating ? <FontAwesomeIcon icon={solid('spinner')} spin size="lg" /> : 'Save'}
      </Button>

      <SuccessAlert
        entity="user settings"
        action="edit"
        open={displaySuccess}
        handleClose={() => setDisplaySuccess(false)}
      />
    </Layout>
  );
}

AccountSettings.propTypes = {
  currentUser: PropTypes.object,
  updating: PropTypes.bool,
  onUpdateUserSettings: PropTypes.func
};
export default AccountSettings;
