import { PropTypes } from 'prop-types';

import { Box, Container } from '@mui/material';

function Layout({ children, ...rest }) {
  return (
    <Container maxWidth="md" {...rest}>
      {children}
    </Container>
  );
}

Layout.propTypes = {
  children: PropTypes.node
};
export default Layout;
