import { PropTypes } from 'prop-types';

import { ClerkProvider } from '@clerk/clerk-react';
import { dark } from '@clerk/themes';
import ClerkActiveOrganizationManager from 'components/ClerkActiveOrganizationManager';

import { STORAGE_KEYS } from 'utils/local-store-utils';

import App from './App';
import { AuthorizedApolloProvider } from './authorized-apollo-client';

if (!process.env.REACT_APP_CLERK_PUBLISHABLE_KEY) {
  throw new Error('Missing Publishable Key');
}
const clerkPubKey = process.env.REACT_APP_CLERK_PUBLISHABLE_KEY;

function Root(props) {
  const { withSideBar } = props;
  const isDarkTheme = JSON.parse(localStorage.getItem(STORAGE_KEYS.modePreferred)) === 'dark';

  return (
    <ClerkProvider
      publishableKey={clerkPubKey}
      appearance={{
        baseTheme: isDarkTheme ? dark : undefined
      }}
    >
      <AuthorizedApolloProvider>
        <ClerkActiveOrganizationManager>
          <App withSideBar={withSideBar} />
        </ClerkActiveOrganizationManager>
      </AuthorizedApolloProvider>
    </ClerkProvider>
  );
}

Root.propTypes = {
  withSideBar: PropTypes.bool
};

export default Root;
