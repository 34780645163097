export const dashboardRoute = () => '/mydezk';
export const retrosDashboardRoute = () => '/retrodezk';
export const newRetroRoute = template => (template ? `/retro/new?t=${template}` : '/retro/new');
export const loginRoute = () => '/login';
export const authorizeRoute = () => '/authorize';
export const ideaDezkRoute = () => '/feedbackdezk';
export const ideaRoute = slug => `/feedbackdezk/${slug}`;
export const ideaRouteById = id => `/feedbackdezk/id${id}`;
export const settingsRoute = () => '/settings';
export const adminDezkRoute = () => '/admindezk';

export const notificationsRoute = () => '/notifications';

const adminDezkManagerRoute = manager => {
  var path = '';
  switch (manager) {
    case 'categories':
    case 'status':
    case 'company':
    case 'invites':
    case 'users':
      path = `${adminDezkRoute()}/${manager}`;
      break;
    default:
      path = adminDezkRoute();
      break;
  }
  return path;
};
export const adminCategoriesManagerRoute = () => adminDezkManagerRoute('categories');
export const adminStatusManagerRoute = () => adminDezkManagerRoute('status');
export const adminCompanySettingsRoute = () => adminDezkManagerRoute('company');
export const adminInvitesRoute = () => adminDezkManagerRoute('invites');
export const adminUsersRoute = () => adminDezkManagerRoute('users');

export const setActiveCompanyRoute = () => '/active-company-redirect';

export const orgSwitcherRoute = () => '/switch-org';
