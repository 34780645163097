import { useEffect, useState } from 'react';

import { useAuth } from '@clerk/clerk-react';

import { STORAGE_KEYS } from '../utils/local-store-utils';

export default function useIsOwner() {
  const { isLoaded, isSignedIn, orgRole } = useAuth();
  const [isOwner, setIsOwner] = useState(false);
  const isLoading = !isLoaded;
  useEffect(() => {
    if (isLoaded && isSignedIn) {
      setIsOwner(orgRole === 'admin');
      localStorage.setItem(STORAGE_KEYS.isOwner, orgRole === 'admin');
    }
  }, [isLoaded, isSignedIn, orgRole]);

  return { isOwner, isLoading };
}
