export const STORAGE_KEYS = {
  modePreferred: 'dezkr_mode_preferred',
  lastSentNotification: 'dezkr_last_sent_notification',
  unsentNotificationsNumber: 'dezkr_unsent_notifications_number',
  companyName: 'dezkr_company_name',
  companyId: 'dezkr_company_id',
  companyLogo: 'dezkr_company_logo',
  companySubdomain: 'dezkr_company_subdomain',
  companyPricingPlan: 'dezkr_company_pricing_plan',
  isOwner: 'dezkr_is_owner',
  isTrialActive: 'dezkr_is_trial_active',
  isTrialExpired: 'dezkr_is_trial_expired',
  eligibleForFreeTrial: 'dezkr_eligible_for_free_trial',
  trialExpiryDate: 'dezkr_trial_expiry_date',
  companyIdClerk: 'dezkr_company_id_clerk'
};
