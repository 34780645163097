import { useLocation, useNavigate } from 'react-router-dom';

import { PropTypes } from 'prop-types';

import { SignInButton, UserButton, useUser } from '@clerk/clerk-react';
import logo from 'images/logo_transparent.png';

import AccountSettings from 'features/AccountSettings';

import useIsOwner from 'common/hooks/useIsOwner';

import { STORAGE_KEYS } from 'utils/local-store-utils';
import {
  adminCategoriesManagerRoute,
  adminCompanySettingsRoute,
  adminDezkRoute,
  adminInvitesRoute,
  adminStatusManagerRoute,
  adminUsersRoute,
  ideaDezkRoute,
  notificationsRoute
} from 'utils/routes';
import { getSubdomainHref, isAppSubdomain, marketingWebsite } from 'utils/subdomain-utils';

import NotificationsBadge from './NotificationsBadge';

import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Box, Drawer, IconButton, Tooltip } from '@mui/material';
import { styled } from '@mui/system';

const StyledDrawer = styled(Drawer)(({ theme: { spacing } }) => ({
  width: 60,
  flexShrink: 0,

  ['& .MuiDrawer-paper']: {
    width: 60,
    height: '100%',
    boxSizing: 'border-box',
    alignItems: 'center',
    padding: spacing(2, 4),
    justifyContent: 'space-between'
  }
}));

function SideBar() {
  const navigate = useNavigate();
  const location = useLocation();

  const { isSignedIn, user } = useUser();

  const companyLogo = JSON.parse(localStorage.getItem(STORAGE_KEYS.companyLogo));

  const { isOwner } = useIsOwner();
  const logoutHref = getSubdomainHref();

  const NAVIGATION_BUTTONS = [
    {
      tooltipText: 'Feedback Dezk',
      Icon: () => <FontAwesomeIcon size="lg" icon={solid('lightbulb')} />,
      active: location.pathname === ideaDezkRoute(),
      handleClick: function () {
        navigate(ideaDezkRoute());
      },
      disabled: isAppSubdomain
    },
    isOwner
      ? {
          tooltipText: 'Admin Dezk',
          Icon: () => <FontAwesomeIcon size="lg" icon={solid('chart-bar')} />,
          handleClick: function () {
            navigate(adminDezkRoute());
          },
          active: [
            (adminCategoriesManagerRoute(),
            adminStatusManagerRoute(),
            adminCompanySettingsRoute(),
            adminInvitesRoute(),
            adminUsersRoute())
          ].includes(location.pathname),
          disabled: isAppSubdomain
        }
      : false,
    isSignedIn
      ? {
          tooltipText: 'Notifications',
          Icon: () => (
            <NotificationsBadge user={user}>
              <FontAwesomeIcon size="lg" icon={solid('bell')} />
            </NotificationsBadge>
          ),
          handleClick: function () {
            navigate(notificationsRoute());
          },
          active: location.pathname === notificationsRoute()
        }
      : false
  ].filter(Boolean);

  return (
    <StyledDrawer variant="permanent">
      <div>
        <Tooltip placement="right-start" title="www.dezkr.com">
          <IconButton
            size="small"
            sx={{ '&>img': { height: 38, width: 38 } }}
            component="a"
            href={marketingWebsite}
            target="_blank"
          >
            <img src={companyLogo || logo} alt="Dezkr Logo" />
          </IconButton>
        </Tooltip>
      </div>

      {/* Navigation */}
      <div>
        {NAVIGATION_BUTTONS.map(({ tooltipText, Icon, handleClick, active, ActiveIcon, disabled }) =>
          renderSidebarButton(tooltipText, Icon, handleClick, active, ActiveIcon, disabled)
        )}

        {/* Settings */}
        {isSignedIn ? (
          <>
            {/* <Box my={2}>
              <IconButton>
                <FontAwesomeIcon size="lg" icon={solid('right-left')} />
              </IconButton>
            </Box> */}
            <Box my={2}>
              <UserButton afterSignOutUrl={logoutHref}>
                <UserButton.UserProfilePage
                  label="Preferences"
                  url="custom"
                  labelIcon={<FontAwesomeIcon size="lg" icon={solid('gear')} />}
                >
                  <AccountSettings />
                </UserButton.UserProfilePage>
              </UserButton>
            </Box>
          </>
        ) : (
          <SignInButton>
            <Tooltip title="Login">
              <IconButton
                color={'default'}
                size="small"
                sx={{
                  my: 2
                }}
              >
                <FontAwesomeIcon size="lg" icon={solid('user')} />
              </IconButton>
            </Tooltip>
          </SignInButton>
        )}
      </div>
    </StyledDrawer>
  );

  function renderSidebarButton(tooltipText, Icon, handleClick, active, ActiveIcon, disabled) {
    return (
      <Tooltip placement="right-start" title={!disabled && tooltipText} key={tooltipText}>
        <IconButton
          onClick={handleClick}
          disabled={disabled}
          size="small"
          sx={{
            my: 2
          }}
          color={active ? 'primary' : 'default'}
        >
          <Icon />
        </IconButton>
      </Tooltip>
    );
  }
}

SideBar.propTypes = {
  themeMode: PropTypes.oneOf(['dark', 'light'])
};
export default SideBar;
