import { getDomain, getSubdomain as getSd } from 'tldts';

const APP_SUBDOMAIN = 'app';
const LOCAL_HOST = 'local.test';
const PORT = 3000;

export const getSubdomain = () => getSd(window.location.href);

export const isAppSubdomain = getSubdomain() === APP_SUBDOMAIN;

export const getSubdomainHref = subdomain => {
  const sd = subdomain || getSubdomain();
  const domain = getDomain(window.location.href);

  return `${window.location.protocol}//${sd}.${domain === LOCAL_HOST ? `${domain}:${PORT}` : domain}`;
};

export const guestLandingPageHref = getSubdomainHref(APP_SUBDOMAIN);

export const marketingWebsite = 'https://www.dezkr.com/';
