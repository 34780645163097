import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { gql, useMutation } from '@apollo/client';

import { STORAGE_KEYS } from 'utils/local-store-utils';
import { showGrowthUpgradeBanner, showTrialUpgradeBanner } from 'utils/pricing-plan-utils';

import { tAndC } from './TAndC';

import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  Typography
} from '@mui/material';

const START_TRIAL_MUTATION = gql`
  mutation StartTrial($companyId: Int!) {
    update_companies(_set: { trial_active: true, pricing_plan_id: 2 }, where: { id: { _eq: $companyId } }) {
      returning {
        id
        trial_active
        trial_expired
      }
    }
  }
`;

const UPGRADE_TO_GROWTH_MUTATION = gql`
  mutation UpgradeGrowth($companyId: Int!) {
    update_companies(_set: { trial_active: false, pricing_plan_id: 2 }, where: { id: { _eq: $companyId } }) {
      returning {
        id
        trial_active
        trial_expired
      }
    }
  }
`;

function StartUpgradeModal({ isOpen, onClose }) {
  const [accepted, setAccepted] = useState(false);
  const [upgrading, setUpgrading] = useState(false);
  const [success, setSuccess] = useState(false);
  const navigate = useNavigate();

  const companyId = JSON.parse(localStorage.getItem(STORAGE_KEYS.companyId));

  const [startTrialMutation, { loading: startingTrial, error: trialError }] = useMutation(START_TRIAL_MUTATION, {
    variables: {
      companyId
    }
  });

  const [upgradeToGrowthMutation, { loading: upgradingToGrowth, error: growthError }] = useMutation(
    UPGRADE_TO_GROWTH_MUTATION,
    {
      variables: {
        companyId
      }
    }
  );

  const handleAcceptanceChange = () => {
    setAccepted(!accepted);
  };

  const handleUpgrade = () => {
    if (accepted) {
      setUpgrading(true);
      showTrialUpgradeBanner && startTrialMutation();
      showGrowthUpgradeBanner && upgradeToGrowthMutation();
    } else {
      alert('Please accept the terms and conditions to proceed.');
    }
  };

  const handleClose = () => {
    if (success) navigate(0);
    onClose();
  };

  useEffect(() => {
    if (upgrading && (!startingTrial || !upgradingToGrowth)) {
      setUpgrading(false);
      !growthError && !trialError && setSuccess(true);
    }
  }, [growthError, startingTrial, trialError, upgrading, upgradingToGrowth]);

  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>{upgrading ? 'Upgrading...' : success ? 'Success!' : 'Upgrade Plan'}</DialogTitle>
      <DialogContent>
        {upgrading ? (
          <CircularProgress />
        ) : success ? (
          <DialogContentText>
            <Typography variant="body1">Congratulations on Upgrading!</Typography>
            {showGrowthUpgradeBanner && (
              <Typography variant="body1">
                You will be charged $50/month for the growth plan. Our team will get in touch with you for the payment
                details
              </Typography>
            )}
          </DialogContentText>
        ) : (
          <DialogContentText>
            <Typography variant="body1">
              {showGrowthUpgradeBanner &&
                'The growth plans costs $50/month. You will received payment details in your emails.'}
              Please read and accept the terms and conditions below to upgrade.
            </Typography>
          </DialogContentText>
        )}
        {!success && !upgrading ? (
          <>
            <Box height={500} overflow="scroll">
              <div dangerouslySetInnerHTML={{ __html: tAndC }} />
            </Box>
            <FormControlLabel
              control={<Checkbox checked={accepted} onChange={handleAcceptanceChange} color="primary" />}
              label="I accept the terms and conditions"
              sx={{ my: 1 }}
            />
          </>
        ) : null}
      </DialogContent>
      {!upgrading && !success ? (
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
          <Button onClick={handleUpgrade} color="primary" disabled={upgrading}>
            {showTrialUpgradeBanner ? 'Start Trial' : 'Upgrade to Growth'}
          </Button>
        </DialogActions>
      ) : (
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      )}
    </Dialog>
  );
}

export default StartUpgradeModal;
