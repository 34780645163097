import { Suspense, useMemo } from 'react';
import { useState } from 'react';
import { Outlet } from 'react-router-dom';

import { PropTypes } from 'prop-types';

import { useUser } from '@clerk/clerk-react';

import SideBar from 'common/SideBar';

import { STORAGE_KEYS } from 'utils/local-store-utils';
import { getTheme } from 'utils/theme';

import { Box, LinearProgress } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';

function App(props) {
  const { withSideBar } = props;
  const { user } = useUser();

  const [themeMode, setThemeMode] = useState(() => {
    if (JSON.parse(localStorage.getItem(STORAGE_KEYS.modePreferred))) {
      return JSON.parse(localStorage.getItem(STORAGE_KEYS.modePreferred));
    }
    if (user) {
      return user.unsafeMetadata?.STORAGE_KEYS?.modePreferred;
    }
    return undefined;
  });

  const theme = useMemo(() => getTheme(themeMode || 'light'), [themeMode]);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline />

        {withSideBar && <SideBar />}
        <Suspense fallback={<LinearProgress />}>
          <Box display="flex" justifyContent="center" alignItems="center">
            <Outlet />
          </Box>
        </Suspense>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

App.propTypes = {
  withSideBar: PropTypes.bool
};
export default App;
