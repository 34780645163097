import React from 'react';
import { createBrowserRouter } from 'react-router-dom';

import { OrganizationSwitcher, SignIn, SignedIn } from '@clerk/clerk-react';
import AdminProtectedComponent from 'components/AdminProtectedComponent';
import Error from 'components/ErrorPage';

import AdminDezkLayout from 'features/AdminDezk/AdminDezkLayout';

import {
  adminCategoriesManagerRoute,
  adminCompanySettingsRoute,
  adminDezkRoute,
  adminStatusManagerRoute,
  ideaDezkRoute,
  ideaRoute,
  ideaRouteById,
  loginRoute,
  notificationsRoute,
  orgSwitcherRoute,
  setActiveCompanyRoute
} from 'utils/routes';
import { getSubdomainHref } from 'utils/subdomain-utils';

import { adminInvitesRoute, adminUsersRoute } from '../common/utils/routes';
import Root from './Root';
import companyLoader from './companyLoader';

const PublicIdeaDezk = React.lazy(() => import('features/Ideas/PublicIdeaDezk'));

const AdminStats = React.lazy(() => import('features/AdminDezk/AdminStats'));
const CategoriesManager = React.lazy(() => import('features/AdminDezk/CategoriesManager'));
const AdminCompanySettingsManager = React.lazy(() => import('features/AdminDezk/AdminCompanySettingsManager'));
const StatusManager = React.lazy(() => import('features/AdminDezk/StatusManager'));
const AdminInvitesManager = React.lazy(() => import('features/AdminDezk/AdminInvitesManager'));
const AdminUsersManager = React.lazy(() => import('features/AdminDezk/AdminUsersManager'));

const IdeaPage = React.lazy(() => import('features/Ideas/IdeaPage'));
const LandingPage = React.lazy(() => import('features/LandingPage'));
const Notifications = React.lazy(() => import('features/Notifications'));
const ActiveCompanyManager = React.lazy(() => import('features/ActiveCompanyManager'));

const router = createBrowserRouter([
  {
    path: '/',
    element: <Root withSideBar />,
    errorElement: <Error />,
    children: [
      {
        index: true,
        element: <LandingPage />
      },
      {
        path: loginRoute(),
        element: <SignIn />
      }
    ]
  },
  {
    element: <Root withSideBar />,
    errorElement: <Error />,
    loader: companyLoader,
    children: [
      {
        errorElement: <Error />,
        children: [
          {
            path: setActiveCompanyRoute(),
            element: (
              <SignedIn>
                <ActiveCompanyManager />
              </SignedIn>
            )
          },
          {
            path: ideaDezkRoute(),
            element: <PublicIdeaDezk />
          },
          {
            path: ideaRoute(':slug'),
            element: (
              <SignedIn>
                <IdeaPage />
              </SignedIn>
            )
          },
          {
            path: ideaRouteById(':id'),
            element: (
              <SignedIn>
                <IdeaPage />
              </SignedIn>
            )
          },
          {
            path: notificationsRoute(),
            element: (
              <SignedIn>
                <Notifications />
              </SignedIn>
            )
          },
          {
            path: orgSwitcherRoute(),
            element: (
              <SignedIn>
                <OrganizationSwitcher
                  hidePersonal
                  createOrganizationUrl={getSubdomainHref('app')}
                  createOrganizationMode="navigation"
                  organizationProfileMode="navigation"
                  organizationProfileUrl={adminCompanySettingsRoute()}
                  defaultOpen={true}
                />
              </SignedIn>
            )
          },
          {
            path: adminDezkRoute(),
            element: <AdminProtectedComponent Component={AdminDezkLayout} />
          },
          {
            path: adminDezkRoute(),
            element: <AdminProtectedComponent Component={AdminDezkLayout} />,
            children: [
              {
                errorElement: <Error />,
                children: [
                  {
                    index: true,
                    element: <AdminProtectedComponent Component={AdminStats} />
                  },
                  {
                    path: adminCategoriesManagerRoute(),
                    element: <AdminProtectedComponent Component={CategoriesManager} />
                  },
                  {
                    path: adminStatusManagerRoute(),
                    element: <AdminProtectedComponent Component={StatusManager} />
                  },
                  {
                    path: adminCompanySettingsRoute(),
                    element: <AdminProtectedComponent Component={AdminCompanySettingsManager} />
                  },
                  {
                    path: adminInvitesRoute(),
                    element: <AdminProtectedComponent Component={AdminInvitesManager} />
                  },
                  {
                    path: adminUsersRoute(),
                    element: <AdminProtectedComponent Component={AdminUsersManager} />
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  }
]);

export default router;
