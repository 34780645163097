import { Link, useLocation } from 'react-router-dom';
import { Outlet } from 'react-router-dom';

import UpgradeBanner from 'components/UpgradeBanner';

import Layout from 'common/Layout';

import { STORAGE_KEYS } from 'utils/local-store-utils';
import {
  adminCategoriesManagerRoute,
  adminCompanySettingsRoute,
  adminDezkRoute,
  adminInvitesRoute,
  adminStatusManagerRoute
} from 'utils/routes';

import { adminUsersRoute } from '../../common/utils/routes';

import { Box, Button, Typography } from '@mui/material';

function AdminDezkLayout() {
  const location = useLocation();

  return (
    <Box justifySelf="flex-start" width="800px">
      <Typography variant="h5" mt={2}>
        {JSON.parse(localStorage.getItem(STORAGE_KEYS.companyName))} Settings
      </Typography>
      <Box display="flex" justifyContent="space-evenly" my={4} borderBottom="1px solid" borderColor="divider">
        {Object.entries(ADMIN_SETTINGS_CONFIG).map(([key, value]) => (
          <Button
            component={Link}
            key={key}
            to={value.route}
            variant="text"
            disabled={location.pathname === value.route}
          >
            {value.text}
          </Button>
        ))}
      </Box>

      <Outlet />
    </Box>
  );
}
const ADMIN_SETTINGS_CONFIG = {
  default: {
    text: 'Admin Dezk',
    route: adminDezkRoute()
  },

  category: {
    text: 'Manage Categories',
    route: adminCategoriesManagerRoute()
  },
  status: {
    text: 'Manage Status Types',
    route: adminStatusManagerRoute()
  },
  invites: {
    text: 'Invitations',
    route: adminInvitesRoute()
  },
  users: {
    text: 'Users',
    route: adminUsersRoute()
  }
};

export default AdminDezkLayout;
