import { Link } from 'react-router-dom';

import LostImg from '../images/unauthorized.jpeg';

import { Button, Container, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledContainer = styled(Container)(({ theme: { palette } }) => ({
  height: '100vh',
  position: 'absolute',
  width: '100%',
  bottom: 0,
  textAlign: 'center'
}));

const StyledDiv = styled('div')(({ theme: { spacing } }) => ({
  width: '100%',
  height: '100%',
  padding: spacing(5)
}));

const StyledImg = styled('img')(() => ({
  height: 500
}));

function UnauthorizedPage() {
  return (
    <StyledContainer maxWidth="xl">
      <StyledImg src={LostImg} />
      <StyledDiv>
        <Typography variant="h3" color="primary" align="center">
          Lost your way?
        </Typography>
        <Typography variant="body2" my={5}>
          Don't worry. We got your back
        </Typography>
        <Button component={Link} to="/" size="large" variant="contained">
          Head back to safety
        </Button>
      </StyledDiv>
    </StyledContainer>
  );
}

export default UnauthorizedPage;
