import { STORAGE_KEYS } from './local-store-utils';

const EXTRA_FEATURES_CONFIG = {
  notes: 'idea_notes',
  pinnedIdea: 'pinned_idea',
  customLogo: 'custom_logo'
};

const companyHasPricingPlan = !!JSON.parse(localStorage.getItem(STORAGE_KEYS.companyPricingPlan));

const extra_features = companyHasPricingPlan
  ? JSON.parse(localStorage.getItem(STORAGE_KEYS.companyPricingPlan?.extra_features))
  : [];

// export const canShowNotes = extra_features?.includes(EXTRA_FEATURES_CONFIG.notes);

// export const canSetPinnedIdea = extra_features?.includes(EXTRA_FEATURES_CONFIG.pinnedIdea);

export const canSetCustomLogo = extra_features?.includes(EXTRA_FEATURES_CONFIG.customLogo);

const pLabel = JSON.parse(localStorage.getItem(STORAGE_KEYS.companyPricingPlan))?.label;

export const pricingPlanLabel = pLabel || 'FREE';
const isOwner = JSON.parse(localStorage.getItem(STORAGE_KEYS.isOwner));

export const showTrialUpgradeBanner =
  isOwner &&
  (JSON.parse(localStorage.getItem(STORAGE_KEYS.companyPricingPlan))?.id === 1 || !companyHasPricingPlan) &&
  JSON.parse(localStorage.getItem(STORAGE_KEYS.eligibleForFreeTrial));

export const showGrowthUpgradeBanner =
  isOwner &&
  JSON.parse(localStorage.getItem(STORAGE_KEYS.companyPricingPlan))?.id !== 2 &&
  (JSON.parse(localStorage.getItem(STORAGE_KEYS.isTrialActive)) ||
    !JSON.parse(localStorage.getItem(STORAGE_KEYS.eligibleForFreeTrial)));
