import { SignedIn, useAuth } from '@clerk/clerk-react';

import UnauthorizedPage from './UnauthorizedPage';

import { LinearProgress } from '@mui/material';

const AdminProtectedComponent = ({ Component, compProps }) => {
  const { orgRole, isLoaded, isSignedIn } = useAuth();

  if (!isLoaded) <LinearProgress />;

  if (!isSignedIn || orgRole !== 'admin') return <UnauthorizedPage />;

  return (
    <SignedIn>
      <Component {...compProps} />
    </SignedIn>
  );
};

export default AdminProtectedComponent;
