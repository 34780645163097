import { ApolloClient, ApolloLink, ApolloProvider, InMemoryCache, createHttpLink, from } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { getMainDefinition } from '@apollo/client/utilities';
import { useAuth } from '@clerk/clerk-react';

import { createClient } from 'graphql-ws';

import { LinearProgress } from '@mui/material';

export function AuthorizedApolloProvider({ children }) {
  const { getToken, isLoaded, isSignedIn, orgRole } = useAuth();

  if (!isLoaded) return <LinearProgress />;

  const httpLink = createHttpLink({
    uri: process.env.HASURA_API
  });

  const authLink = setContext(async (_, { headers = {}, ...context }) => {
    if (isSignedIn) {
      const authToken = await getToken({ template: 'hasura-dev-backend' });
      if (orgRole === 'admin' || orgRole === 'org:co_owner') {
        headers['x-hasura-role'] = 'owner';
      }
      return {
        headers: {
          ...headers,
          authorization: `Bearer ${authToken}`
        },
        ...context
      };
    } else {
      return {
        headers,
        ...context
      };
    }
  });

  const authHttpLink = authLink.concat(httpLink);

  const wsLink = new GraphQLWsLink(
    createClient({
      url: process.env.HASURA_WS_API,
      reconnect: true,
      connectionParams: async () => {
        const authToken = await getToken({ template: 'hasura-dev-backend' });
        return {
          headers: {
            authorization: `Bearer ${authToken}`,
            ['x-hasura-role']: orgRole === 'admin' ? 'owner' : 'user'
          }
        };
      }
    })
  );

  const errorLink = onError(({ graphQLErrors, networkError, operation, forward }) => {
    if (graphQLErrors)
      graphQLErrors.forEach(({ message, locations, path }) =>
        console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`)
      );

    if (networkError) console.log(`[Network error]: ${networkError}`);

    forward(operation);
  });

  const splitTerminalLink = new ApolloLink.split(
    ({ query }) => {
      const { kind, operation } = getMainDefinition(query);
      return kind === 'OperationDefinition' && operation == 'subscription';
    },
    wsLink,
    authHttpLink
  );

  const apolloClient = new ApolloClient({
    link: from([errorLink, splitTerminalLink]),
    cache: new InMemoryCache({
      typePolicies: {
        Query: {
          fields: {
            notifications: {
              keyArgs: false,
              merge(existing = [], incoming) {
                return [...existing, ...incoming];
              }
            }
          }
        }
      }
    })
  });

  return <ApolloProvider client={apolloClient}>{children}</ApolloProvider>;
}
