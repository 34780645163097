import isBefore from 'date-fns/isBefore';
import parseISO from 'date-fns/parseISO';

import { STORAGE_KEYS } from 'common/utils/local-store-utils';
import { getSubdomain } from 'common/utils/subdomain-utils';

const companyLoader = async function () {
  const subdomain = getSubdomain();
  const endpoint = process.env.HASURA_API;
  const headers = {
    'content-type': 'application/json',
    'x-hasura-role': 'public'
  };
  const companyLoaderQuery = {
    operationName: 'isValidCompany',
    query: `query isValidCompany($short_name: String!) {
      companies(where: { short_name: { _eq: $short_name } }) {
        id
        id_clerk
        name
        short_name
        logo
        trial_active
        trial_expired
        eligible_for_free_trial
        pricing_plan {
          id
          label
          extra_features
        }
      }
    }`,
    variables: { short_name: subdomain }
  };

  const options = {
    method: 'POST',
    headers,
    body: JSON.stringify(companyLoaderQuery)
  };

  const response = await fetch(endpoint, options);

  const data = await response.json();
  const stringify = v => JSON.stringify(v === undefined ? null : v);

  localStorage.setItem(STORAGE_KEYS.companyName, stringify(data?.data?.companies[0]?.name));
  localStorage.setItem(STORAGE_KEYS.companyId, stringify(data?.data?.companies[0]?.id));
  localStorage.setItem(STORAGE_KEYS.companyIdClerk, stringify(data?.data?.companies[0]?.id_clerk));

  localStorage.setItem(STORAGE_KEYS.companyLogo, stringify(data?.data?.companies[0]?.logo));
  localStorage.setItem(STORAGE_KEYS.companySubdomain, subdomain);
  localStorage.setItem(STORAGE_KEYS.companyPricingPlan, stringify(data?.data?.companies[0]?.pricing_plan));
  localStorage.setItem(STORAGE_KEYS.isTrialActive, stringify(data?.data?.companies[0]?.trial_active));
  const trialExpiryDate = data?.data?.companies[0]?.trial_expired;
  const isTrialExpired = trialExpiryDate ? isBefore(parseISO(trialExpiryDate), new Date()) : null;
  localStorage.setItem(STORAGE_KEYS.isTrialExpired, stringify(isTrialExpired));
  localStorage.setItem(STORAGE_KEYS.trialExpiryDate, stringify(trialExpiryDate));

  localStorage.setItem(STORAGE_KEYS.eligibleForFreeTrial, stringify(data?.data?.companies[0]?.eligible_for_free_trial));

  return fetch(endpoint, options);
};
export default companyLoader;
