import { createTheme, responsiveFontSizes } from '@mui/material';
import { amber, deepOrange, grey, lime, orange, pink } from '@mui/material/colors';

const customColors = {
  primary: {
    main: '#645cec',
    light: '#9c8aff',
    dark: '#2032ba',
    background: '#afa1ff',
    contrastText: '#fff'
  },

  secondary: {
    main: '#74e4f4',
    light: '#aaffff',
    dark: '#39b2c1',
    background: '#bbffff',
    contrastText: '#000000'
  },
  amber: {
    main: amber[500],
    light: amber[200],
    dark: amber[800],
    background: amber[50],
    contrastText: '#fff'
  },
  dark: {
    main: '#341484',
    light: '#6740b5',
    dark: '#000056',
    background: '#8566c3',
    contrastText: '#fff'
  },
  success: {
    main: '#00ba63',
    light: '#5aee92',
    dark: '#008937',
    background: '#7bf1a7',
    contrastText: '#000000'
  },
  deepOrange: {
    main: deepOrange[500],
    light: deepOrange[200],
    dark: deepOrange[800],
    background: deepOrange[50],
    contrastText: '#fff'
  },
  lime: {
    main: lime[500],
    light: lime[200],
    dark: lime[800],
    background: lime[50],
    contrastText: '#fff'
  },
  orange: {
    main: orange[500],
    light: orange[200],
    dark: orange[800],
    background: orange[50],
    contrastText: '#fff'
  },
  pink: {
    main: pink[500],
    light: pink[200],
    dark: pink[800],
    background: pink[50],
    contrastText: '#fff'
  },
  hover: {
    main: grey[100]
  },
  background: {
    // main: grey[50]
  }
};

const palette_lightMode = { ...customColors };
const palette_darkMode = {
  mode: 'dark',
  ...customColors,
  // in dark mode primary and secondary are switched
  primary: {
    main: '#74e4f4',
    light: '#aaffff',
    dark: '#39b2c1',
    background: '#bbffff',
    contrastText: '#000000'
  },
  secondary: {
    main: '#645cec',
    light: '#9c8aff',
    dark: '#2032ba',
    background: '#afa1ff',
    contrastText: '#fff'
  },
  hover: {
    main: '#15202B'
  }
};

const themeCustomization = {
  shape: { borderRadius: 8, borderRadiusLg: 12 }
};
const thLM = createTheme({
  palette: { ...palette_lightMode },
  ...themeCustomization
});
const thDM = createTheme({
  palette: { ...palette_darkMode },
  ...themeCustomization
});

const theme_lightMode = responsiveFontSizes(thLM);
const theme_darkMode = responsiveFontSizes(thDM);

export function getTheme(mode) {
  return mode === 'dark' ? theme_darkMode : theme_lightMode;
}
