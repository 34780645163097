import { useMutation, useQuery } from '@apollo/client';
import { useUser } from '@clerk/clerk-react';

import GetUserSettingsQuery from './GetUserSettingsQuery.graphql';
import updateUserSettingsMutation from './UpdateUserSettingsMutation.graphql';

import UserAccountSettings from './UserAccountSettings';

import { LinearProgress } from '@mui/material';

function AccountSettingsCtnr() {
  const { user } = useUser();

  const [updateUserSettings, { loading: updating }] = useMutation(updateUserSettingsMutation, {
    variables: {
      userId: user?.id
    }
  });

  function handleUpdateUserMetadata(updates) {
    user.update({
      unsafeMetadata: {
        ...updates
      }
    });
  }

  return (
    <UserAccountSettings
      onUpdateUserSettings={updateUserSettings}
      updating={updating}
      updateUserMetadata={handleUpdateUserMetadata}
    />
  );
}

AccountSettingsCtnr.propTypes = {};
export default AccountSettingsCtnr;
