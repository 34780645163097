import { PropTypes } from 'prop-types';

import { Alert, Snackbar } from '@mui/material';

function SuccessAlert(props) {
  const { open, handleClose, entity, action } = props;

  const verb = () => {
    switch (action) {
      case 'edit':
        return 'updated';
      case 'create':
        return 'created';
      case 'delete':
        return 'deleted';
    }
  };

  return (
    <Snackbar open={open} autoHideDuration={10000} onClose={handleClose}>
      <Alert severity="success" onClose={handleClose}>
        {entity} {verb()} successfully!
      </Alert>
    </Snackbar>
  );
}

SuccessAlert.propTypes = {
  entity: PropTypes.oneOf(['category', 'status', 'company', 'user settings']),
  action: PropTypes.oneOf(['edit', 'create', 'delete']),
  open: PropTypes.bool,
  handleClose: PropTypes.func
};
export default SuccessAlert;
