import { Link, useRouteError } from 'react-router-dom';

import ErrorImg from '../images/error.svg';

import { Button, Container, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledContainer = styled(Container)(({ theme: { palette } }) => ({
  height: '100vh',
  position: 'absolute',
  width: '100%',
  bottom: 0,
  textAlign: 'center',
  '&::before': {
    content: '""',
    display: 'block',
    position: 'absolute',
    borderRadius: '70% 50% 65%',
    width: '60%',
    height: '100%',
    transform: 'translate(85%, 60%)',
    backgroundColor: palette.primary.light,
    opacity: 0.5
  }
}));

const StyledDiv = styled('div')(({ theme: { spacing } }) => ({
  width: '100%',
  height: '100%',
  padding: spacing(5)
}));

const StyledImg = styled('img')(() => ({
  height: 300
}));

function Error(props) {
  // const { message, route } = props;
  const error = useRouteError();
  console.log(error);
  return (
    <StyledContainer maxWidth="xl">
      <StyledImg src={ErrorImg} />
      <StyledDiv>
        <Typography variant="h3" color="primary" align="center">
          Oops, Something went wrong
        </Typography>
        <Typography variant="body1" color="primary" align="center"></Typography>
        <Typography variant="body2" my={10}>
          {error.data || 'We are working on it. Thanks for your patience'}
        </Typography>
        <Button component={Link} to="/" size="large" variant="contained">
          Head back to safety
        </Button>
      </StyledDiv>
    </StyledContainer>
  );
}

export default Error;
