import { useEffect } from 'react';

import { PropTypes } from 'prop-types';

import { gql, useSubscription } from '@apollo/client';

import { STORAGE_KEYS } from 'utils/local-store-utils';

import { Badge } from '@mui/material';

const LastSentNotificationSubscription = gql`
  subscription LastSentNotificationSubscription($userId: String!) {
    users_by_pk(id: $userId) {
      last_sent_notification
    }
  }
`;

const UnsentNotificationsSubscription = gql`
  subscription UnsentNotificationsSubscription($lastSentNotificationDate: timestamptz) {
    notifications_aggregate(where: { created_at: { _gt: $lastSentNotificationDate } }) {
      aggregate {
        count
      }
    }
  }
`;

function NotificationsBadge(props) {
  const { user, children } = props;

  const lastSentNotificationDate = JSON.parse(localStorage.getItem(STORAGE_KEYS.lastSentNotification)) || 1577836800;

  const unsentNotificationsNumber = JSON.parse(localStorage.getItem(STORAGE_KEYS.unsentNotificationsNumber));

  const {
    data: lastSentNotificationData,
    loading: lastSentNotificationLoading,
    error: lastSentNotificationError
  } = useSubscription(LastSentNotificationSubscription, {
    skip: !user,
    shouldResubscribe: true,
    variables: {
      userId: user?.id
    }
  });

  const { data, loading, error } = useSubscription(UnsentNotificationsSubscription, {
    shouldResubscribe: true,
    skip: !lastSentNotificationDate,
    variables: {
      lastSentNotificationDate: lastSentNotificationDate
    }
  });

  useEffect(() => {
    if (lastSentNotificationData?.users_by_pk.last_sent_notification) {
      if (lastSentNotificationDate !== lastSentNotificationData?.users_by_pk?.last_sent_notification) {
        localStorage.setItem(
          STORAGE_KEYS.lastSentNotification,
          JSON.stringify(lastSentNotificationData?.users_by_pk?.last_sent_notification)
        );
      }
    }
  }, [lastSentNotificationDate, lastSentNotificationData?.users_by_pk?.last_sent_notification]);

  useEffect(() => {
    if (data) {
      if (unsentNotificationsNumber !== data?.notifications_aggregate.aggregate.count) {
        localStorage.setItem(
          STORAGE_KEYS.unsentNotificationsNumber,
          JSON.stringify(data?.notifications_aggregate?.aggregate.count)
        );
      }
    }
  });

  return (
    <Badge badgeContent={unsentNotificationsNumber} color="error" invisible={!parseInt(unsentNotificationsNumber)}>
      {children}
    </Badge>
  );
}

NotificationsBadge.propTypes = {
  user: PropTypes.object,
  children: PropTypes.node
};
export default NotificationsBadge;
