import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useAuth, useOrganizationList, useUser } from '@clerk/clerk-react';

import { STORAGE_KEYS } from 'utils/local-store-utils';
import { getSubdomain, isAppSubdomain } from 'utils/subdomain-utils';

import { LinearProgress } from '@mui/material';

export default function ClerkActiveOrganizationManager({ children }) {
  const { orgId, isLoaded, orgSlug } = useAuth();

  const { user } = useUser();
  const { setActive, isLoaded: isOrgsLoaded } = useOrganizationList();
  const subdomain = getSubdomain();
  const [inProgress, setInProgress] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (!isAppSubdomain && isOrgsLoaded) {
      if (!orgSlug || orgSlug !== subdomain) {
        const isUserMember = user.organizationMemberships.find(({ organization: { slug } }) => slug === subdomain);
        setInProgress(true);
        if (isUserMember) {
          const localStoreOrgId = JSON.parse(localStorage.getItem(STORAGE_KEYS.companyIdClerk));
          setActive({ organization: localStoreOrgId }).then(() => {
            console.log('org set');
            setInProgress(false);
          });
        } else {
          const cloudFunctionsEndpoint = `${process.env.NETLIFY_CLOUD_FUNCTIONS_ENDPOINT}create-org-membership`;
          fetch(cloudFunctionsEndpoint, {
            'Content-Type': 'application/json',
            method: 'POST',
            mode: 'no-cors',
            body: JSON.stringify({
              userId: user.id,
              orgId: JSON.parse(localStorage.getItem(STORAGE_KEYS.companyIdClerk))
            })
          }).then(r => {
            setInProgress(false);
            navigate(0);
          });
        }
      }
    }
  }, [isOrgsLoaded, navigate, orgId, orgSlug, setActive, subdomain, user]);

  if (!isLoaded || inProgress) return <LinearProgress />;

  return <>{children}</>;
}
